import React from 'react'
import Layout from '../../templates/layout'
import ServicesComponent from '../../components/organisms/Services'
import Clients from '../../components/organisms/Clients'
import CTA from '../../components/organisms/CTA'
import Seo from '../../helpers/seo'
import Slogan from '../../components/molecules/Slogan'

import './styles.scss'

const Services = () => {
  return (
    <Layout page="Services">
      <Slogan>
        <b>Serviços</b>
      </Slogan>
      <ServicesComponent withTitle="without-title" />
      <Clients />
      <div className="services-principal__cta">
        <CTA />
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Serviços" />

export default Services
